import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import {
  Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom';

import './App.css';
import LandingPage from './landing_page/landing_page.js';
import CaseStudy from './CaseStudy.js';
const GA_TRACKING_ID = 'UA-64791632-8';

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});

/** View that renders the header of the website
 * @return {Object} JSX to render
*/
const Header = () => {
  return <Link to="/" className="menu-wrapper">
    <div className="footer-spacer" />
    <img className="menu-banner" alt="Banner"
      src="/images/banner.png" />
    <div className="footer-spacer" />
  </Link>;
};

/** View that renders the footer of the website
 * @return {Object} JSX to render
*/
const Footer = () => {
  return <div className="footer-wrapper">
    <div className="footer-spacer" />
    <div className="footer">
      <Link to="/about">about</Link> | <a href={`mailto:kevinmkarol@gmail.com`}><img className="link-icon email-icon" alt="email"
        src="/images/envelope.svg" /></a> <a href='https://www.linkedin.com/in/kevin-karol-292b0b53/'><img className="link-icon" alt="linkedin"
          src="/images/linkedin.svg" /></a> <a href='https://www.goodreads.com/user/show/53476707-kevin-karol
'><img className="link-icon" alt="linkedin"
          src="/images/goodreads.svg" /></a> |  <a href='/karol_resume.pdf'>resume</a>
    </div>
    <div className="footer-spacer" />
  </div>
};

class About extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    let aboutText = "For the past decade Kevin has been exploring how technology can transform interactions in physical spaces. He's explored how interaction technology can create emotionally resonant theater; personality and behavior systems for home robots; and interactions between autonomous vehicles, riders and other road users. Through this work he's had the opportunity to collaborate with some of the top software engineers, animators, game designers, actors, and hardware engineers in the world."

    let personalText = "In his free time Kevin enjoys trail running, swing dancing, and experimenting with new technology. He's an avid reader, cinephile, and enjoys exploring the latest video games and VR titles."

    let attributionText = "Selected photos and videos on this site were created by Anki Inc, Zoox Inc, and Louis B. Stein photography."

    return <div className="about-base">
      <div className="about-wrapper">
        <div className="about-spacer" />
        <img className="about-photo" alt="profile_photo" src="/images/about_photo.jpeg" />
        <div className="about-spacer" />
      </div>
      <div className="break" />
      <div className="about-wrapper">
        <div className="about-spacer" />
        <h2 className="about-header">About Kevin</h2>
        <div className="about-spacer" />
      </div>
      <div className="break" />
      <div className="about-wrapper">
        <div className="about-spacer" />
        <p className="about-description">{aboutText}</p>
        <div className="about-spacer" />
      </div>
      <div className="break" />
      <div className="about-wrapper">
        <div className="about-spacer" />
        <p className="about-description">{personalText}</p>
        <div className="about-spacer" />
      </div>
      <div className="break" />
      <div className="about-wrapper">
        <div className="about-spacer" />
        <p className="about-description">{attributionText}</p>
        <div className="about-spacer" />
      </div>
      <div className="break" />
      <div className="home-button-about">
        <div className="about-spacer" />
        <Link to="/" className="about-description">Back Home</Link>
        <div className="about-spacer" />
      </div>

    </div>
  }
};

const HomeWrapper = (props) => {
  return <div className="home-wrapper">
    {props.subComp({ "paramID": props.paramID })}
    <div className="break" />
    <Link to="/" className="home-button">Back Home</Link>
  </div>
}

class App extends Component {
  /**
  * @constructor
  * @param {Object} props - standard react props
  */
  constructor(props) {
    super(props);
    ReactGA.initialize(GA_TRACKING_ID);
  }
  /**  Returns screen to render
   * @return {Object} JSX to render
  */
  render() {
    return (
      <Router history={history}>
        <div className="app-base">
          <Header />
          <div className="break" />
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/case-study/:id" render={(props) => <HomeWrapper paramID={props.match.params.id} subComp={CaseStudy} />} />
            <Route exact path="/about" component={About} />} />
            <Route exact path="/karol_resume.pdf" to="/karol_resume.pdf" />
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>
          <div className="break" />
          <Footer />
        </div>
      </Router>
    );
  }
}


export default App;
