import './CaseStudy.css';
import React, { Component } from 'react';
import {
  Redirect
} from 'react-router-dom';

class CozmoStudy extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return <div className="case-study-text">
      <h2>A character, not a robot</h2>
      <p>When Cozmo launched in 2016, there was nothing like him in the home robotics space. More than a novelty product with a few tricks,
        Cozmo was frequently described as being a character straight out of a Pixar movie. It was an apt comparison since many of the animators
        who brought Cozmo to life had previously worked at Pixar, Disney, EA and other top animation studios. As a member of the AI team specializing
        in behavior systems and personality, I worked closely with these animators and the company's game design team to develop human/robot interactions
        and bring them to life through robot “behaviors”.
      </p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <iframe className="embedded-iframe" src="https://www.youtube.com/embed/BWymDFX8PlY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <div className="case-study-img-padding" />
      </div>
      <p>Each behavior was designed with a clear goal, like turning a cube upright or bringing it to a user, and Cozmo’s personality
        shone through as he succeeded or failed to make progress towards this goal. We created these behaviors through a series of hard-coded
        animations and programmatically executed actions like rolling or stacking a cube. The joy of the product came from interacting with these
        behaviors and seeing how Cozmo responded emotionally to successes and failures. Delivering on this value proposition meant that the AI behavior
        system needed to blend together discrete character states and interactions into a single, believable character arc.
      </p>
      <p>Animators traditionally have complete control over their character’s actions, camera angles, and can "cheat physics" to make a character feel more real.
        A robot character moves a lot of this control from the animator to the viewer, and the behavior system needs to make up the difference for the character
        to stay believable. When Cozmo sets a goal, the user can “mess" with the robot’s plans by hiding objects or obstructing his path. When Cozmo starts moving
        through the space or interacting with an object, users can move to watch from a  different angle, changing the “camera angle”. When Cozmo wants to telegraph
        his next action, “antics” that warp the character’s body and “cheat physics” for clear communication aren’t an option, lessening the user’s
        insight into character motivations. The AI system needed to account for the user’s actions
        and adjust Cozmo’s animations so that the character stayed believable.
      </p>
      <p>Fortunately developing technology-infused interactive theater gave me a background building stories where the
        user was in control of narrative progression and could change events through their interactions.
        To ship a satisfying character when Cozmo launched, I worked with our
        animation and game design teams to take the linear stories of each behavior and transform them into branching narratives that made Cozmo feel
        responsive to his environment.
      </p>
      <p>For example: Cozmo accomplishes his goal and turns back to look at the user for a celebration. How does the story change if the user has moved to
        a different part of the table and Cozmo can't see them anymore? Or what if they're still there but because of backlighting Cozmo can't see their face?
        The AI system considered these possibilities and selected an animation with a believable response given Cozmo’s personality.
      </p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <img className="case-study-img" src="/images/behavior_development.png" alt="behavior-development-diagram" />
        <div className="case-study-img-padding" />
      </div>
      <p>After launching Cozmo, we continued to release new behaviors every month. Each new behavior meant a combinatorial increase in the number of
        character transitions. This made it more difficult to deliver the core value proposition of a character who felt alive each release. We  developed
        a formal review structure of new behaviors where the animation team focused on Cozmo's emotional arc, the game design team focused on compelling
        interactions for the player; and my team worked through the best way to fit the narrative into the robot’s branching decision structure.
        However, these review structures were cumbersome and slowed down our ability to ship new behaviors. As our product became more complex,
        it was clear that the AI system needed to evolve to deliver a more intricate personality and increase our behavior development speed.
      </p>
      <h2>Vector, the desktop companion</h2>
      <p>With Cozmo's success, Anki set its sights on an audience beyond toys. Blurring the lines between robot companion, voice assistant, and digital pet,
        Vector was a major technical leap forward for the company. With a new audience came a new character and the opportunity to evolve
        the AI system to make character consistency the rule instead of the exception.
      </p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <iframe className="embedded-iframe" src="https://www.youtube.com/embed/Qy2Z2TWAt6A" title="Vector YoutubePromo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <div className="case-study-img-padding" />
      </div>
      <p>Vector added new user inputs through touch sensors and voice activated commands, inputs that dramatically increased the frequency of behavior interruptions.
        To keep character continuity after these brief interruptions we wanted Vector to return to his previous set of goals and behaviors, but with emotional
        modifiers when the user had done or said nice things. Whereas Cozmo had a silly personality and could forget what he was doing without breaking character
        Vector was more mature and stable in his behavioral states - a quality the previous high level behavior system didn't support. So I set out to develop a new high
        level behavior system that solved the combinatorial behavior problems from Cozmo and gave Vector a greater continuity of goal with emotional modifiers.
      </p>
      <p>
        The result was an architecture that allowed behaviors to be easily sliced into logical subsets. Each subset maintained a common goal state
        but included emotional variations and transitions into/out of the state. No matter where Vector was within the goal state he could use
        the common in/out transitions to respond to interrupts and then resume his goal with new emotional context.
        Discussions between the AI, game design and animation teams focused on two major character transition types:
        transitions between major goal states, and subtle emotional shifts within the behavior subset. We could confidently add new behaviors within a
        subset without worrying about character consistency in the rest of the experience, making the character consistent by default and increasing our
        rate of behavior production.
      </p>
      <h2>From Individual Interactions to Consistent Character</h2>
      <p>Cozmo felt like a movie character because we identified how a robot differs form an animated character and
        developed AI behavior systems to compensate. These systems ensured he always felt alive and responsive through careful branching narrative authorship.
        Vector evolved from a toy with a silly, forgetful personality to a pet by evolving from careful authorship to
        an AI system that guaranteed character consistency by default. Through this evolution we continuosly increased
        the quality of individual interactions, increased the rate of behavior production and delivered on Anki's central
        value proposition of a living robot character in your home.
      </p>
    </div>
  }
};

class ZooxStudy extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {

    return <div className="case-study-text">
      <h2>Autonomous Experience From the Ground Up</h2>
      <p>As the head of Zoox's Experience Prototyping team, I've had the pleasure of working across our experience design, vehicle design and software development teams to
        develop novel, yet intuitive, interactions for our customers. These intearactions spanned the entire product experience including ride booking, entering the vehicle, and enjoying the ride.
        Although much of our work is still under wraps, last December
        the company unveiled our vehicle in San Francisco, a glimpse into the future we see for riders.</p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <iframe className="case-study-img embedded-iframe" src="https://www.youtube.com/embed/RrqV_sm0lLQ" title="YouTube video player"
          frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <div className="case-study-img-padding" />
      </div>
      <p>Prototyping new experiences for a product that is in motion and involves multiple physical, digital, and sonic touchpoints requires a clear understanding of how
        each element interacts and impacts the user’s experience during their journey. With this context in hand, I work closely with Zoox’s user research and design teams
        to identify the most important questions for each interaction. Then we take these requirements and build tests to capture the impact of new ideas.
        Finally, we share results and updated requirement requests with our engineering partners and executive leadership for review and production implementation.</p>
    </div>
  }
};

class InRepStudy extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return <div className="case-study-text">
      <h2>Augmented reality creation for everyone</h2>
      <p>Tools like Unity, Blender and online tutorials give engineers the power to create XR games and interactive environments.
        But theater and installation artists, who specialize in immersive environments and narrative experiences, can’t efficiently explore
        their ideas and develop art that audiences can enjoy without a background in programming and 3D art. To unlock this creative avenue
        for non-technical artists, I set out to develop an accessible XR creation platform that anyone could create things with in minutes, not weeks or months.
      </p>
      <p>This shortened creation cycle mirrors the difference between creating content for a platform like YouTube vs. creating on TickTock.
      </p>
      <p>YouTube videos are often produced using professional grade programs like Logic Pro and Adobe After Effects
        (Unity and Blender within the analogy). These systems are relatively affordable and well documented, but creators need to invest
        time to become familiar and efficient with the tools. Even then, depending on the type of content you want to create, the workflow may
        have a ton of wasted steps and time. If you saw a video of someone singing on YouTube and wanted to harmonize with them you'd need to
        download the video, import it into a video editing program, record your voice/video overtop, upload the video to your YouTube account
        and then link back to the original, a process that won't be worth the effort for most users or artists.
      </p>
      <p>Tick-Tock takes a different approach, making it easy for users to create video content straight from their phones by removing the
        flexibility of a general-purpose program like After Effects and instead letting users easily create within set templates.
        If a user wants to harmonize with a video on TickTock, they use TickTock’s Duet functionality. Instead of worrying about passing
        files back and forth, the user stays on their phone and leverages its built in camera and microphone to quickly harmonize and re-post to the platform.
      </p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <iframe className="embedded-iframe" src="https://www.youtube.com/embed/lK3RLIy7Rgo" title="TickTock Duets" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <div className="case-study-img-padding" />
      </div>
      <p> Are there similar “templates” for XR creation that use a phone’s functionality to quickly compose creative new XR experiences? 
          I decided to experiment and find out.
      </p>
      <h2>Technology Proliferation and Novel Experiences</h2>
      <p>Since recording audio is one of the easiest ways to create content on a smartphone, and since it’s already a key component
        in professional XR creations, it made sense to start by exploring how to leverage audio as part of simplified XR templates.
        The increasing prominence of spatial audio on mobile devices presented opportunities to take a creator’s recorded audio and “place”
        the sound in the audience’s environment which could create immersive dialogues and scenes. While this could be a template for creation,
        it was fairly limited in scope, so a way to easily visually transform the space was next on the list.
      </p>
      <p>Since lighting is a key element in XR and theatrical environments, I started exploring how it could add to the XR toolbox.
        Philips Hue lightbulbs feature a programmatic API to control their lightbulbs. Simple GUIs already exist for setting the lighting color
        in a room, so giving users the ability to setup multiple states and transitions between them was an easy first step. With some software development
        I built a tool where users could create choreographed lighting experiences across multiple light bulbs that transformed a space.
        Users could design their own room-spanning experiences, similar to this "extended TV" light show from Philips Hue:
      </p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <iframe className="embedded-iframe" src="https://www.youtube.com/embed/OQNyvSbc50I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <div className="case-study-img-padding" />
      </div>
      <p>Seeing choreographed lighting and sound in a space immediately transformed it into a stage to my eye, which presented an interesting opportunity.
        If choreographed lights and sounds can transform a living room into a stage, then could performers be a third element in the experience?
        How would the XR toolkit give creators a way to direct the performers?
        How would the performers stay in sync with the programmed lighting and sound? And could the performers create the show in realtime, without rehearsals?
      </p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <img className="installation-padding case-study-img-small" height="300px" src="/images/isolation_2013.jpeg" alt="Performers with cellphones" />
        <img className="installation-padding case-study-img-small" height="300px" src="/images/isolation_2009.jpeg" alt="Performers with cellphones" />
        <div className="case-study-img-padding" />
      </div>
      <p>I’d previously mounted a production called <i>Isolation: A Collaborative Piece</i> in which audience members received phone calls
        and text messages with instructions about how to create the performance in realtime. Without any rehearsal the group created a dynamic
        performance that transformed the space. This same technique, using realtime audio and text instructions to create a piece, could be the way that
        creators directed performers within an XR experience. So I expanded the XR tools to include realtime audio instructions which the creator 
        can synchronize with specific lighting and sound cues.
      </p>
      <h2>From Theater To XR Games</h2>
      <p>With a baseline XR toolkit in place, I started focusing on creating experiences with light, sound and realtime performers.
         Some experiences focused on traditional performances with an audience and a (digital or human) performer at the center of the experience, but 
         the most fruitful experiments trended away from theater and towards games. There was no need to limit the number of "performers" receiving
         instructions, and giving participants simultaneous instructions created fun new surprises and opportunities. One of the most successful experiments involved synchronized
         motion between two participants. Each time you stepped in sync with your partner it created an eerie sense of physical connection. Each time you 
         broke stride it created a sense of unease. And when you were asked to close your eyes and trust that the performance wouldn't end in a collision
         it tapped into primal senses of hope and dread.
      </p>
      <p>After months of exploration I've barely scratched the surface of what these simple tools can create. Each new component
         has exponentially increased the experiences the toolkit enables, with marginal increases to the toolset complexity.
         This has proved the potential for a template based approach to XR experience creation for non-technical artists.
      </p>
    </div>
  }
};


class TempleStudy extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return <div className="case-study-text">
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <iframe className="embedded-iframe" src="https://www.youtube.com/embed/w1DpMpn2OCI" frameBorder="0" allowFullScreen="" title="Legends of Hidden Temple Promo"></iframe>
        <div className="case-study-img-padding" />
      </div>
      <p>The lights shining in your eyes. Music pulsing through the stadium. A crowd of thousands watching your every move.
        Have you ever wondered how you would fair on a gameshow?
      </p>
      <p>I designed and built an interactive installation to give attendees of Carnegie Mellon University’s
        Carnival that experience. As part of a booth based around the 90s television show
        <a href="https://en.wikipedia.org/wiki/Legends_of_the_Hidden_Temple"> Legends of the Hidden Temple</a>,
        the game utilized programmatically controlled DMX lighting, real time video compositing and editing,
        and environmental design to give players the experience of being on a gameshow.
      </p>
      <h2>Game Design</h2>
      <p>To transport users to the world of the show I built the installation using its iconic elements
        including music, team emblems, and large buttons that let the player progress through the game.
        Tasks on the show were often simple, with time pressure and environmental effects serving as the primary obstacle.
        The game needed to be short to keep traffic moving through the booth, while providing the exhilarating experience of
        being at the center an action packed TV program.
      </p>
      <p>This led to a design where the game started simply: when a team emblem lights up, press the corresponding button to receive a point.
        The mechanic was easy to grasp and led the player into a false sense of confidence. However, after the first five points,
        instead of one light turning on, two activated at the same time. Users needed to press both buttons simultaneously to score a point.
      </p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <img className="case-study-img" src="/images/legends_game/gallery_10.jpg" alt="Game booth with hanging vines and buttons" />
        <div className="case-study-img-padding" />
      </div>
      <p>This transformed the game from a simple stimulus-response experience into a cross between Twister and rock climbing.
        Since the buttons were spread out across two different heights and three different walls, players were surrounded.
        To complete the “simple” task players’ bodies were constantly in motion, scanning for lights and then contorting their
        arms into position to score a point.
      </p>
      <p>Environmental pressures added to the challenge. One wall was covered with moss, and another was covered with hanging vines.
        Hitting the buttons often involved pushing these barriers aside or feeling your way across the material. Meanwhile,
        programmatically controlled lights flashed all around you as pulsing music filled the space, building pressure as the game progressed.
        Throughout the game a live 3-camera feed of the player's progress was displayed on a nearby TV, completing the experience.
      </p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <img className="case-study-img" src="/images/legends_game/gallery_2.png" alt="Composited output of the contestant on the show" />
        <div className="case-study-img-padding" />
      </div>
      <p>The game also featured a children's mode which removed the coordination elements of the experience.
        It used the three lowest indicator lights so that the buttons were within arms reach, and stayed a stimulus-response
        experience so that younger users could enjoy pressing the buttons and standing in the changing lights.
      </p>
      <h2>Technical Infrastructure</h2>
      <p>I built the installation with:<br /></p>
      <ul>
        <li><a href="https://www.amazon.com/Raspberry-Pi-RASP-PI-3-Model-Motherboard/dp/B01CD5VC92/ref=sr_1_6?s=pc&amp;ie=UTF8&amp;qid=1468227802&amp;sr=1-6&amp;keywords=raspberry+pi+2">Raspberry Pi</a><br /></li>
        <li><a href="https://www.amazon.com/gp/product/B00N2VIALK/ref=oh_aui_detailpage_o07_s00?ie=UTF8&amp;psc=1">3 Ethernet cables</a><br /></li>
        <li><a href="https://www.amazon.com/gp/product/B000N99BBC/ref=oh_aui_detailpage_o09_s03?ie=UTF8&amp;psc=1">Ethernet splitter</a><br /></li>
        <li><a href="https://www.amazon.com/gp/product/B00NF06Q6Y/ref=oh_aui_detailpage_o01_s00?ie=UTF8&amp;psc=1">8 LED DMX Lights</a><br /></li>
        <li><a href="http://dmxking.com/usbdmx/ultradmxmicro">USB to DMX</a><br /></li>
        <li><a href="https://www.amazon.com/gp/product/B0013Y033Q/ref=oh_aui_detailpage_o00_s00?ie=UTF8&amp;psc=1">DMX cabling</a><br /></li>
        <li><a href="https://www.amazon.com/gp/product/B008ZVRAQS/ref=oh_aui_detailpage_o02_s01?ie=UTF8&amp;psc=1">3 Webcams</a><br /></li>
        <li><a href="https://www.amazon.com/gp/product/B00CFL6ARO/ref=oh_aui_detailpage_o02_s01?ie=UTF8&amp;psc=1">USB Extenders</a><br /></li>
        <li><a href="http://www.digital-loggers.com/lpc.html">Ethernet controlled Power Strip</a><br /></li>
        <li><a href="https://www.amazon.com/AmazonBasics-Powered-Computer-Speakers-A100/dp/B00GHY5F3K/ref=sr_1_1?ie=UTF8&amp;qid=1468228286&amp;sr=8-1&amp;keywords=amazon+speakers">Speakers</a><br /></li>
        <li>Additional wiring, GPIO female headers and power strips<br /></li>
      </ul>

      <p>And the following software:<br /></p>
      <ul>
        <li><a href="http://www.qlcplus.org/">QLC+</a><br /></li>
        <li><a href="https://cycling74.com/products/max/#.V4NhZZMrJTY">Max 7</a><br /></li>
      </ul>

      <img className="case-study-img" src="/images/legends_game/hardware.png" alt="Wiring diagram of hardware connections" />
      <p>Two computers handled all of the automation of the game: a Raspberry Pi handled the interactions with physical hardware, and a Windows server
        handled media assets. The two machines were networked together using ethernet and OSC.</p>
      <p>The Raspberry Pi utilized its GPIO pins to listen for the user's physical button presses. It communicated these button presses to the Windows server,
        and then issued curl requests to the ethernet controlled power strip in order to turn the conventional colored lightbulbs behind the team emblems on and off.</p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <img className="installation-padding case-study-img-small" src="/images/legends_game/wiring_tangled.jpg" alt="Server and wires that run game" />
        <img className="installation-padding case-study-img-small" height="375" src="/images/legends_game/gallery_4_5.jpg" alt="Light behind the game" />
        <div className="case-study-img-padding" />
      </div>
      <p>The Windows server, running Max 7, had three media outputs: audio, DMX lighting and realtime video.
        In addition to the main audio track which served as the game’s timekeeping mechanism, voice over cues were
        triggered every 5 points to let the player know how they were doing.
        The DMX lighting cues were programmed using QLC+, but triggered using OSC messages sent from Max 7 timed with the audio.
        Live video was captured by three cameras placed throughout the installation, and then edited together by Max in real time.
        In addition to switching video feeds, the output video had assets from the original television show
        (which had been scraped off of YouTube and then re-constructed from component parts in photoshop) overlaid in real time,
        displaying the player’s current score and remaining time.
        The game also featured a persistent high score, and between matches a looping menu screen displayed the all time high score and the score of the most recent game.</p>
      <p>The full source code is available on <a href="https://github.com/kevinmkarol/lotht_game">Github</a>.</p>
      <h2 id="360-experience"><b>360 Experience</b></h2>
      <p>Want to experience the installation first hand?  Check out the 360 Experience:</p>
      <div className="case-study-wrapper">
        <div className="case-study-img-padding" />
        <iframe className="embedded-iframe" src="https://www.youtube.com/embed/puwgY1SeGvM" frameBorder="0" allowFullScreen="" title="360 Installation Experience"></iframe>
        <div className="case-study-img-padding" />
      </div>
    </div>
  }
};

const CaseStudy = (props) => {
  let caseName = props.paramID;
  var caseStudy = null;

  if (caseName === "cozmo") {
    caseStudy = <CozmoStudy />
  } else if (caseName === "zoox") {
    caseStudy = <ZooxStudy />
  } else if (caseName === "inrep") {
    caseStudy = <InRepStudy />
  } else if (caseName === "temple") {
    caseStudy = <TempleStudy />
  } else {
    return <Redirect to='/' />
  }

  return <div className="case-study-base">
    {caseStudy}
  </div>
};


export default CaseStudy;
