import React from 'react';
import {
  Link,
} from 'react-router-dom';
import '../App.css';

const CaseStudyPromptVertical = (props) => {
  return <Link to={props.link} className="vertical-prompt">
           <h2 className="case-study-title">{props.title}</h2>
           <img className="vertical-case-image"  alt="project" src={props.image} />
           <h2 className="case-study-description">{props.subheading}</h2>
         </Link> 
};

/** UI component focused on the first screen the user sees
*/
class LandingPage extends React.Component {
  /**
   * @constructor
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /** React component destroyed*/
  componentWillUnmount() {
  }

  /**  Create the home screen
   * @return {Object} JSX to render
  */
  render() {
    return (
      <div className="landing-page-base">

            <CaseStudyPromptVertical title="Social Robotics" subheading="Cozmo's Behavior Engine" image="images/cozmo-playful.jpeg" link="/case-study/cozmo"/>
            <CaseStudyPromptVertical title="Autonomous Vehicles" subheading="Prototyping Vehicle Interactions" image="images/zoox_experience.png" link="/case-study/zoox" />
            <div className="break" />
            <CaseStudyPromptVertical title="Augmenting Reality" subheading="Developing XR Creation Tools" image="images/inrep_shot.png" link="/case-study/inrep"/>
            <CaseStudyPromptVertical title="Gameshow Installation" subheading="Legends of the Hidden Temple" image="images/temple_teaser.jpg" link="/case-study/temple"/>
      </div>

    );
  }
}

export default LandingPage;
